// 代理接口
import {POST} from "../servers/request";
import {GET} from "../servers/request";

//定义一个空挂载对象
let Proxy = {}

// 请求列表
var ProxyList = {
    // regdit 注册
    regdit(data = {}){
        return POST({
            'url':'index/user/do_register',
            params:data,
        })
    },

    // login 登录
    login(data = {}){
        return POST({
            'url':'index/user/do_login',
            params:data,
        })
    },

    // 获取语言
    getvuelang(data = {}){
        return GET({
            'url':'index/user/getvuelang',
            params:data,
        })
    },

    //测试token
    testToken(data = {}){
        return POST({
            'url':'hitoken',
            //添加请求头
            head:{'Authorization':localStorage.getItem('token')},
            params:data,
        })
    },

    // 获取导航nav
    getNav(data = {}){
        return POST({
            'url':'getNav',
            head:{'Authorization' : localStorage.getItem('token')},
            params:data,
        })
    }
}

// 添加一个挂载方法
Proxy.install = Vue=>{
    Vue.prototype.$proxy = ProxyList
}

// 暴露接口
export default Proxy